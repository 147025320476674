import React from 'react';

import Layout from '../components/Layout';

import Footer from '../components/Footer';
import Navigation from '../components/Navigation';
import ProjectList from '../components/ProjectList';
import Header from '../components/Header';

const IndexPage = () => {

  return (
    <Layout>
      <Navigation />
      <Header />

      <ProjectList />
      <Footer />
    </Layout>
  )
};

export default IndexPage;
