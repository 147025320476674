import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import SocialLinks from './SocialLinks';
import EmailContact from './EmailContact';

export default function Footer() {

  const footerImage = useStaticQuery(graphql`
    query FooterImageQuery {
      file(relativePath: {eq: "bg-footer.jpg"}) {
        childImageSharp {
          sizes(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpSizes
          }
        }
      }
    }
  `);

  return (
    <footer className="footer">
      <Img
        className="img-fluid header-image"
        alt="Mountains in the background"
        imgStyle={{ objectPosition: 'center' }}
        sizes={footerImage.file.childImageSharp.sizes} />
      <section id="contact" className="contact-section">
        <div className="container">
          <EmailContact /> 

          <SocialLinks />
        </div>
      </section>
      <div className="container copyright">
        Copyright &copy; Gatsby-starter-grayscale 2019
      </div>
    </footer>
  );
}
