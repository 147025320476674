import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import EmailIcon from '../assets/icons/envelope-solid.svg'

export default () => {

  const data = useStaticQuery(graphql`
    query EmailQuery {
      site {
        siteMetadata {
          email
        }
      }
    }
  `);

  const email = data.site.siteMetadata.email;

  return (
    <div className="row justify-content-md-center">
      <div className="col-md-4 mb-3 mb-md-0">
        <div className="card py-4 h-100">
          <div className="card-body text-center">
            <EmailIcon className="email-icon"/>
            <h4 className="text-uppercase m-0">Email</h4>
            <hr className="my-4" />
            <div className="small text-black-50">
              <a href={`mailto:${email}`}>{email}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}