import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import TwitterIcon from '../assets/icons/twitter-brands.svg';
import LinkedInIcon from '../assets/icons/linkedin-in-brands.svg';
import GithubIcon from '../assets/icons/github-brands.svg';

export default () => {

  const data = useStaticQuery(graphql`
    query SocialLinksQuery {
      site {
        siteMetadata {
          socialLinks {
            icon
            name
            url
          }
        }
      }
    }
  `); 

  const socialLinks = data.site.siteMetadata.socialLinks;

  const getIcon = (icon) => {
    switch (icon) {
      case 'twitter':
        return TwitterIcon;
      case 'linkedin':
        return LinkedInIcon;
      case 'github':
        return GithubIcon;
      default:
        return null;
    }
  }

  return (
    <div className="social d-flex justify-content-center">
      {socialLinks.map(social => {
        const { icon, url } = social;

        const Icon = getIcon(icon);

        if (!Icon) return null;

        return (
          <a 
            target="_blank"
            rel="noopener noreferrer"
            key={url} 
            href={url} 
            className={`mx-2`}>
              <Icon className="social-icon" />
          </a>
        );
      })}
    </div>
  )
}