import React, { useState } from 'react';

import Project from './Project';
import { useStaticQuery, graphql } from 'gatsby';

export default () => {
  const projectStep = 3;

  const [currentProjectLimit, setCurrentProjectLimit] = useState(projectStep);

  let projects = useStaticQuery(graphql`
    query ProjectQuery {

      allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}) {
        edges {
          node {
            frontmatter {
              description
              link
              image {
                childImageSharp {
                  sizes(maxWidth: 650, quality: 90) {
                    ...GatsbyImageSharpSizes
                  }
                }
              }
              imageAlt
              title
            }
          }
        }
      }
    }
  `);

  const moreProjects = () => {
    if (currentProjectLimit < projects.length) {
      setCurrentProjectLimit(currentProjectLimit + projectStep);
    }
  }

  projects = projects.allMarkdownRemark.edges.map((item) => item.node.frontmatter);

  return (
    <section id="projects" className="projects-section bg-light">
      <div className="container projects-section-wrapper">
        {projects.slice(0, currentProjectLimit).map((project, index) => <Project key={project.title} index={index} {...project} />)}
        {currentProjectLimit < projects.length
          ? (
            <button
              onClick={moreProjects}
              className="more-button">
              More
            </button>
          )
          : null}

      </div>
    </section>
  )
}