import React, { useEffect, useState } from 'react';
import Scroll from './Scroll';
import { useStaticQuery, graphql } from 'gatsby';

export default () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [visibilityClass, setVisibilityClass] = useState('');

  const data = useStaticQuery(graphql`
    query NavDataQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const { title } = data.site.siteMetadata;

  const toggleMenu = (value) => {
    setOpenMenu(value);
  }

  useEffect(() => {
    const body = document.querySelector('body');
    if (window.innerWidth < 992) {
      if (openMenu) {
        if (!body.classList.contains('no-body-scroll')) {
          body.classList.add('no-body-scroll');
        }
      } else {  
        body.classList.remove('no-body-scroll');
      }
    } else {
      body.classList.remove('no-body-scroll');
    }
    
  }, [ openMenu ])

  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      setVisibilityClass('navbar-shrink');
    } else {
      setVisibilityClass('');
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <nav
      className={`navbar navbar-expand-lg navbar-light fixed-top ${visibilityClass}`}
      id="mainNav"
    >
      <div className="container">
        <a className="navbar-brand" href="#page-top">
          {title}
        </a>
        <button
          onClick={_ => toggleMenu(!openMenu)}
          className={`navbar-toggler navbar-toggler-right ${
            openMenu ? '' : 'collapsed'
            }`}
          type="button"
          aria-controls="navbarResponsive"
          aria-expanded={openMenu}
          aria-label="Toggle navigation"
        >
          <div className="navbar-toggler-bars">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
        <div
          className={`collapse navbar-collapse ${openMenu ? 'show' : ''}`}
          id="navbarResponsive"
        >
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Scroll
                onClick={_ => toggleMenu(!openMenu)}
                type="id"
                offset={-150}
                element="projects"
              >
                <a className="nav-link" href="#projects">
                  Projects
                </a>
              </Scroll>
            </li>
            <li className="nav-item">
              <Scroll
                onClick={_ => toggleMenu(!openMenu)}
                type="id"
                element="contact"
              >
                <a className="nav-link" href="#contact">
                  Contact
                </a>
              </Scroll>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

