import React from 'react';
import Img from 'gatsby-image';

export default ({ title, description, link, image, imageAlt, index }) => (
  <div className="row justify-content-center no-gutters mb-5 mb-lg-5">
    <div className="col-lg-6">
      <Img 
        className="img-fluid project-image" 
        alt={imageAlt} 
        imgStyle={{objectPosition: 'center'}} 
        sizes={image.childImageSharp.sizes} />
    </div>
    <div className={`col-lg-6${index % 2 === 0 ? '' : ' order-lg-first'}`}>
      <div className="text-center h-100 project">
        <div className="d-flex h-100">
          <div className="project-text w-100 my-auto text-center text-lg-left">
            <h4 className="text-white">{title}</h4>
            <p className="mb-0 text-white-50">
              {description}
            </p>
            <a
              href={link} 
              target="_blank">View</a>
            <hr className="d-none d-lg-block mb-0 ml-0" />
          </div>
        </div>
      </div>
    </div>
  </div>
)